import React, { useState } from "react";
import "../../Assets/Style/TrackShip.scss";
import Button from "../../components/Button";
import { Textbox } from "../../components/FormInputs";
import Header from "../../components/Header";
import HomeFooter from "../../components/Footer";
import TrackInfo from "./components/TrackInfo";
import axios from "axios";
import { trackURL } from "../../Library/URLs";
import { generateTransactionID } from "../../Library/Utilities";

const TrackShip = () => {
  const [trackNo, setTrackNo] = useState("");
  const [trackStatus, setTrackStatus] = useState(false);
  const [trackMsg, setTrackMsg] = useState("");
  const [trackSteps, setTrackSteps] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleTrackNo = (e) => {
    setTrackNo(e.target.value);
  };

  const trackShipment = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(trackURL, {
        user_id: "5126",
        transaction_id: generateTransactionID(15),
        company_id: "44",
        user_ses_id: "9531c2794d712f9980f05026a3285d69",
        shipment_number: trackNo,
      })
      .then((res) => {
        setTrackStatus(res.data?.success);
        setIsLoading(false);
        if (!res.data?.success) {
          setTrackMsg(res.data?.message);
        } else if (res.data?.success) {
          // setTrackSteps(res.data?.data?.steps);
          setTimeline(res.data?.data?.events);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      <Header />
      <div className="TrackWrapper">
        <div className="TrackSection">
          <div>
            <h1 className="InfoHero">Track Shipment</h1>
            <p className="InfoSub">
              Get real-time updates of all your packages easily using your
              shipment number
            </p>
            <div className="TrackBtnContain">
              <Textbox
                value={trackNo}
                name="trackNo"
                onChange={handleTrackNo}
                placeholder="Enter Shipment Number"
              />
              <Button
                width={"100%"}
                height={"100%"}
                color={"#E92D38"}
                text={
                  isLoading ? (
                    <span className="spinner-border spinner-grow-sm" />
                  ) : (
                    "TRACK SHIPMENT"
                  )
                }
                onClick={trackShipment}
              />
            </div>
          </div>
        </div>
        <div>
          <TrackInfo
            trackStatus={trackStatus}
            trackMsg={trackMsg}
            trackSteps={trackSteps}
            trackNo={trackNo}
            timeline={timeline}
          />
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default TrackShip;
