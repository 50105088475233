import moment from 'moment';
import React from 'react'
import { BsCheck2 } from "react-icons/bs";

const TrackInfo = ({ trackStatus, trackMsg, trackNo, timeline }) => {
  console.log("dfa", timeline);
  return (
    <div className='TrackInfo' >
      <div className='ShipNumber' >
        <p>Shipment Number : </p>
        <p className='ShipCode' >{trackNo} </p>
      </div>
      {
        trackStatus ? (
          <div className="TrackEvent">
        {/* Row 1 */}
        {
          timeline?.length > 0 &&
          timeline?.map((step, index) => (
            <div className="EventRow" key={index} >
              <div className="Col1">
                <div className='Checked' >
                  <div className={step.timestamp ? "CheckedEvent" : "UnCheckedEvent"}>
                    <BsCheck2 />
                  </div>
                  {
                    index !== 3 && (
                      <div className={step.timestamp ? "CheckedLine" : "UnCheckedLine"} ></div>
                    )
                  }
                </div>
                <p>{step.description} </p>
              </div>
              <div className="Col2">
                <p>{step.timestamp ? moment(step.timestamp).format("DD MMM YYYY hh:mm a") : "N/A"} </p>
              </div>
            </div>
          ))
        }
        {/* <div className="EventRow">
          <div className="Col1">
            <div className='Checked' >
              <div className="CheckedEvent">
                <BsCheck2 />
              </div>
              <div className="CheckedLine"></div>
            </div>
            <p>Shipment created on platform</p>
          </div>
          <div className="Col2">
            <p>Wednesday 5th Sept 10:52 AM</p>
          </div>
        </div> */}
        {/* Row 2 */}
        {/* <div className="EventRow">
          <div className="Col1">
            <div className='Checked' >
              <div className="CheckedEvent">
                <BsCheck2 />
              </div>
              <div className="UnCheckedLine"></div>
            </div>
            <p>Shipment created on platform</p>
          </div>
          <div className="Col2">
            <p>Wednesday 5th Sept 10:52 AM</p>
          </div>
        </div> */}
        {/* Row 3 */}
        {/* <div className="EventRow">
          <div className="Col1">
            <div className='Checked' >
              <div className="UnCheckedEvent">
                <BsCheck2 />
              </div>
              <div className="UnCheckedLine"></div>
            </div>
            <p>Shipment created on platform</p>
          </div>
          <div className="Col2">
            <p>Wednesday 5th Sept 10:52 AM</p>
          </div>
        </div> */}
        {/* Row 4 */}
        {/* <div className="EventRow">
          <div className="Col1">
            <div className='Checked' >
              <div className="UnCheckedEvent">
                <BsCheck2 />
              </div>
            </div>
            <p>Shipment created on platform</p>
          </div>
          <div className="Col2">
            <p>Wednesday 5th Sept 10:52 AM</p>
          </div>
        </div> */}
      </div>
        ) : (
          <div className="TrackMessage">
            <p>{trackMsg}</p>
          </div>
        )
      }
    </div>
  )
}

export default TrackInfo